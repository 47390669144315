import React, { useState, useContext, useEffect } from "react"
import "../../styles/moooFarmBuy.css"
import ReactPlayer from "react-player/youtube"
import { useStaticQuery, graphql } from "gatsby"
import LandingLogo from "../../images/landing/landing-logo.png"
import Instagram from "../../images/landing/instagram.png"
import Facebook from "../../images/landing/facebook.png"
import Linkedin from "../../images/landing/linkedin.png"
import Youtube from "../../images/landing/youtube.png"
import Line from "../../images/landing/line1.png"
import Line2 from "../../images/landing/line2.png"
import call from "../../images/landing/call.png"
import calldisable from "../../images/landing/calldisable.png"

import Img from "gatsby-image"
import { Helmet } from "react-helmet"
import Slider from "react-slick"
import BackgroundImage from "gatsby-background-image"
import { MixpanelContext } from "../../analytics/tracking"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
function MoooFarmBuyMarketing() {
  const mixpanel = useContext(MixpanelContext)
  const [state, setState] = useState({
    CounterHeading: "#C2C2C2",
    CounterSubHeading: "#C2C2C2",
    CounterHeadingGreen: "#38721D",
    CounterSubHeadingGreen: "rgba(0, 0, 0, 0.87)",
    firstShow: true,
    secondShow: false,
    thirdShow: false,
    fourthShow: false,
    phoneNumber: null,
    showButtonBottom: false,
    showCallModal: false,
    disableCallOption: false,
  })
  var fbq = null
  const fbqTrack = (eventName, params = {}) => {
    if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
      fbq("trackCustom", eventName, { ...params })
    }
  }

  useEffect(() => {
    mixpanel.track("MoooFarm Buy Marketing Landing Page")
    fbqTrack("Marketing Landing Page")
  }, [mixpanel])

  function handlePhoneNumber(e) {}
  function handleShowModal(e) {
    setState({ showCallModal: !state.showCallModal })
  }
  const sources = useStaticQuery(graphql`
    query LandingPageImages {
      allFile(filter: { relativeDirectory: { eq: "landing" } }) {
        edges {
          node {
            childImageSharp {
              fluid {
                aspectRatio
                base64
                src
                srcSet
                sizes
              }
            }
            name
          }
        }
      }
    }
  `)

  let testimonial1 = sources.allFile.edges.find(({ node }, index) => {
    if (node.name == "testimonial1") {
      return true
    }
  })
  let testimonial2 = sources.allFile.edges.find(({ node }, index) => {
    if (node.name == "testimonial2") {
      return true
    }
  })
  let testimonial3 = sources.allFile.edges.find(({ node }, index) => {
    if (node.name == "testimonial3") {
      return true
    }
  })
  let step = sources.allFile.edges.find(({ node }, index) => {
    if (node.name == "step") {
      return true
    }
  })
  let sathBanner = sources.allFile.edges.find(({ node }, index) => {
    if (node.name == "sath-banner") {
      return true
    }
  })
  let map = sources.allFile.edges.find(({ node }, index) => {
    if (node.name == "map") {
      return true
    }
  })
  let landingBanner = sources.allFile.edges.find(({ node }, index) => {
    if (node.name == "landing-banner") {
      return true
    }
  })

  const handleScroll = e => {
    if (e.target.scrollTop >= 0 && e.target.scrollTop < 50) {
      setState({
        ...state,
        showButtonBottom: true,
      })
    }
    if (e.target.scrollTop > 50 && e.target.scrollTop < 100) {
      setState({
        ...state,
        firstShow: true,
        secondShow: false,
        thirdShow: false,
        fourthShow: false,
        showButtonBottom: true,
      })
    }
    if (e.target.scrollTop > 101 && e.target.scrollTop < 150) {
      setState({
        ...state,
        firstShow: false,
        secondShow: true,
        thirdShow: false,
        fourthShow: false,
        showButtonBottom: true,
      })
    }
    if (e.target.scrollTop > 151 && e.target.scrollTop < 200) {
      setState({
        ...state,
        firstShow: false,
        secondShow: false,
        thirdShow: true,
        fourthShow: false,
        showButtonBottom: false,
      })
    }
    if (e.target.scrollTop > 201 && e.target.scrollTop < 250) {
      setState({
        ...state,
        firstShow: false,
        secondShow: false,
        thirdShow: false,
        fourthShow: true,
        showButtonBottom: false,
      })
    }
  }

  // render() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    centerPadding: "60px",
  }
  return (
    <div
      id="test"
      onScroll={e => {
        handleScroll(e)
      }}
    >
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
        />
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
      </Helmet>
      <div className="container">
        <BackgroundImage
          className={"banner-container"}
          fluid={landingBanner.node.childImageSharp.fluid}
        >
          <div className="row text-left">
            <div className="col-xs-12">
              <img src={LandingLogo} alt="Logo" className="main-logo" />
              <h2 className="banner-heading">100% गारंटी</h2>
              <p className="banner-sub-heading">
                पर लें{" "}
                <span>
                  <img src={Line} className="Line1" />
                  सबसे सस्ते एवं
                </span>
              </p>
              <p className="banner-sub-heading">
                <span>
                  <img src={Line2} className="Line2" />
                  सबसे बढ़िया
                </span>{" "}
                नस्ल के पशु
              </p>
              <form className="form-inline">
                <div className="form-group">
                  <span>
                    <a
                      style={{marginLeft:"10px"}}
                      type="submit"
                      className="phone-button"
                      onClick={e => {
                        handleShowModal(e)
                      }}
                    >
                      अभी मिस्ड कॉल दें
                    </a>
                  </span>
                  <br />
                </div>
                <br />
                {/* <div className="form-group code">
                  <p className="phone-code">+91</p>
                </div> */}
              </form>
            </div>
          </div>
        </BackgroundImage>
      </div>
      <div className="container">
        <BackgroundImage
          className={"map-container"}
          fluid={map.node.childImageSharp.fluid}
        >
          <div className="row">
            <div className="col-xs-6"></div>
            <div className="col-xs-6">
              <div className="counter1">
                <h3
                  style={{
                    color: !state.firstShow
                      ? state.CounterHeading
                      : state.CounterHeadingGreen,
                    fontSize: "24px",
                    fontFamily: "Mukta",
                    fontWeight: "bold",
                    letterSpacing: "0.15px",
                  }}
                >
                  10 लाख +
                </h3>
                <p
                  style={{
                    color: state.CounterSubHeading1,
                    fontSize: "14px",
                    fontFamily: "Mukta",
                    fontWeight: "600",
                    letterSpacing: "0.15px",
                  }}
                >
                  खरीदार जुड़े हैं मूफार्म के साथ।
                </p>
              </div>
              <div className="counter2">
                <h3
                  style={{
                    color: !state.secondShow
                      ? state.CounterHeading
                      : state.CounterHeadingGreen,
                    fontSize: "24px",
                    fontFamily: "Mukta",
                    fontWeight: "bold",
                    letterSpacing: "0.15px",
                  }}
                >
                  200 +
                </h3>
                <p
                  style={{
                    color: state.CounterSubHeading2,
                    fontSize: "13px",
                    fontFamily: "Mukta",
                    fontWeight: "600",
                    letterSpacing: "0.15px",
                  }}
                >
                  शहरों में हैं मूफार्म की पहुंच।
                </p>
              </div>
              <div className="counter3">
                <h3
                  style={{
                    color: !state.thirdShow
                      ? state.CounterHeading
                      : state.CounterHeadingGreen,
                    fontSize: "24px",
                    fontFamily: "Mukta",
                    fontWeight: "bold",
                    letterSpacing: "0.15px",
                  }}
                >
                  1 लाख +
                </h3>
                <p
                  style={{
                    color: state.CounterSubHeading3,
                    fontSize: "13px",
                    fontFamily: "Mukta",
                    fontWeight: "600",
                    letterSpacing: "0.15px",
                  }}
                >
                  पशु बिक चुके हैं मूफार्म के द्वारा।
                </p>
              </div>
              <div className="counter4">
                <h3
                  style={{
                    color: !state.fourthShow
                      ? state.CounterHeading
                      : state.CounterHeadingGreen,
                    fontSize: "24px",
                    fontFamily: "Mukta",
                    fontWeight: "bold",
                    letterSpacing: "0.15px",
                  }}
                >
                  10 +
                </h3>
                <p
                  style={{
                    color: state.CounterSubHeading4,
                    fontSize: "13px",
                    fontFamily: "Mukta",
                    fontWeight: "600",
                    letterSpacing: "0.15px",
                  }}
                >
                  नस्लें उपलब्ध हैं मूफार्म पर।
                </p>
              </div>
            </div>
          </div>
        </BackgroundImage>
      </div>
      <div className="container video-container">
        <div className="row">
          <div className="col-xs-12">
            <h2 className="video-heading">
              अब खरीदिए अपना पशु हमारे मूफार्म ऐप के द्वारा।
            </h2>
            <div className="react-video-player">
              <ReactPlayer
                className="react-player"
                controls={true}
                width={"100%"}
                height={220}
                style={{
                  borderRadius: 10,
                  overflow: "hidden",
                }}
                url="https://www.youtube.com/watch?v=ffys8MKUOQY"
                light={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container testimonial-container">
        <div className="row">
          <div className="col-xs-12">
            <Slider {...settings}>
              <div key={"1"}>
                <Img
                  style={{ width: "100%" }}
                  fluid={testimonial1.node.childImageSharp.fluid}
                />
              </div>
              <div key={"2"}>
                <Img
                  style={{ width: "100%" }}
                  fluid={testimonial2.node.childImageSharp.fluid}
                />
              </div>
              <div key={"3"}>
                <Img
                  style={{ width: "100%" }}
                  fluid={testimonial3.node.childImageSharp.fluid}
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <div className="container step-container">
        <div className="row">
          <div className="col-xs-12">
            <Img
              fluid={step.node.childImageSharp.fluid}
              className="step-banner"
            />
          </div>
        </div>
      </div>
      <div className="container video-container2">
        <div className="row">
          <div className="col-xs-12">
            <h2 className="video-heading">
              देखिए क्या कहते हैं किसान मूफार्म से ख़रीदे गए पशु के बारे में:
            </h2>
            <div className="react-video-player">
              <ReactPlayer
                className="react-player"
                controls={true}
                width={"100%"}
                height={220}
                style={{
                  borderRadius: 10,
                  overflow: "hidden",
                }}
                url="https://www.youtube.com/watch?v=9vrFUPT1xHc"
                light={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container step-container">
        <div className="row">
          <div className="col-xs-12">
            {/* <img src={SathBanner} className="step-banner" /> */}
            <Img
              fluid={sathBanner.node.childImageSharp.fluid}
              className="step-banner"
            />
          </div>
        </div>
      </div>
      <div className="container call-container">
        <div className="row text-right">
          <div className="col-xs-12">
            <a
              onClick={e => {
                handleShowModal(e)
              }}
            >
              <img
                src={call}
                className="call-button animate__lightSpeedInRight animate__delay-10s"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="container popup-button-container">
        <div className="row popup-button-row text-center">
          {state.showCallModal ? (
            <div>
              <div
                style={{
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #f15773",
                  width: "360px",
                  margin: "auto",
                }}
              >
                <div
                  style={{
                    height: "24px",
                    fontFamily: "Mukta",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    margin: "16px 0px",
                    color: "#000000",
                  }}
                >
                  यहाँ अपना नंबर भरें - हमारी टीम आपसे तुरंत संपर्क करेगी!
                </div>

                <div>
                  <input
                    type="number"
                    id="pwd"
                    placeholder="अपना नंबर यहां भरें "
                    name="pwd"
                    className="phone-input"
                    required
                    onChange={e => {
                      handlePhoneNumber(e)
                    }}
                  />
                </div>
                <div
                  style={{
                    height: "16px",
                    fontFamily: "Mukta",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "13px",
                    lineHeight: "16px",
                    letterSpacing: "0.25px",
                    color: "#000000",
                    margin: "8px 0px",
                  }}
                >
                  आपका नंबर हमारे पास सुरक्षित है
                </div>
              </div>
              <div className="col-xs-12">
                <div className="popup-button">
                  <a
                    href="tel:07901729630"
                    className="popupbtn"
                    onClick={e => {
                      fbqTrack("Missed Call LP1", {
                        phoneNumber: state.phoneNumber,
                      })
                      mixpanel.track("Marketing Page Missed Call Click", {
                        phoneNumber: state.phoneNumber,
                      })
                    }}
                  >
                    पशु खरीदें
                  </a>
                </div>
              </div>
            </div>
          ) : !state.showButtonBottom ? (
            <div className="col-xs-12">
              <div className="popup-button">
                <a
                  className="popupbtn"
                  onClick={e => {
                    handleShowModal(e)
                  }}
                >
                  पशु खरीदें - अभी मिस्ड कॉल दें
                </a>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="container footer-container">
        <div className="row text-center">
          <div className="col-xs-12">
            <img src={LandingLogo} className="footer-logo" />
          </div>
          <div className="col-xs-12 social-background">
            <div className="social-icon">
              <a href="https://www.instagram.com/mooofarm/" target="_blank">
                <img src={Instagram} alt="Instagram" className="social" />
              </a>
              <a href="https://www.facebook.com/mooofarm" target="_blank">
                <img src={Facebook} alt="facebook" className="social" />
              </a>
              <a
                href="https://in.linkedin.com/company/mooofarm"
                target="_blank"
              >
                <img src={Linkedin} alt="Linkedin" className="social" />
              </a>
              <a
                href="https://www.youtube.com/channel/UC1fxWvTk-xYbBAwfQvUCJAQ/videos"
                target="_blank"
              >
                <img src={Youtube} alt="Youtube" className="social" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  // }
}

export default MoooFarmBuyMarketing
